






















































































































































.personalSpecialOrder {
  border-bottom: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
